/*

Global event system

let unlisten = eventManager.listen('event name', function(params, ...) {});
eventManager.dispatch('event name', paramOrParamArray);
unlisten();

 */

export var eventManager = {
    listen: function (event, callback) {
        if (!Object.prototype.hasOwnProperty.call(eventManager._listeners, event)) {
            eventManager._listeners[event] = []
        }
        eventManager._listeners[event].push(callback)
        return () => {
            var index = eventManager._listeners[event].indexOf(callback)
            eventManager._listeners[event].splice(index, 1)
        }
    },
    dispatch: function (event, args) {
        if (Object.prototype.hasOwnProperty.call(eventManager._listeners, event)) {
            if (!Array.isArray(args)) args = [args]

            for (var i = 0; i < eventManager._listeners[event].length; ++i) {
                try {
                    eventManager._listeners[event][i].call(null, args)
                } catch (e) {
                    if (console && console.error) {
                        console.error(e)
                    }
                }
            }
        }
    },
    _listeners: {}
}
