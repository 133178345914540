// Promiseful variables used in ES6 code.
// This module must be imported first by main.js so that they are ready.
var promisefulVariables = [
    'query',
    'FB',
    'FBAppEvents',
    'ProviderMarkerIsReady',
    'AbstractMarkerIsReady'
]

promisefulVariables.forEach(window.addPromisefulVariable)
