import adyenUtils from './adyenUtils'

export { adyenUtils as adyenUtils }

// Should we use /app/startPayment for everything instead of old APIs?
// Handles registering and completing callbacks related to current payment operation.
const paymentOperation = {
    status: 'none',
    successCallback: null,
    errorCallback: null,

    start(successCallback, errorCallback) {
        // log('!!! paymentOperation.start()')

        if (this.status === 'none') {
            this.status = 'active'
        } else {
            throw new Error(`paymentStatus.start(): invalid status ${this.status}`)
        }

        this.successCallback = successCallback
        this.errorCallback = errorCallback
    },

    finish({ success = true, result = null, error = null }) {
        // log(`!!! paymentOperation.finish()`, { success, result, error })

        if (this.status === 'active') {
            this.status = 'none'
        } else {
            throw new Error(`paymentStatus.finish(): invalid status ${this.status}`)
        }

        if (success) {
            this.successCallback(result)
        } else {
            this.errorCallback(error || new Error('Payment failed'))
        }
    }
}

/*
Start a payment flow. Returns a Promise.

Must only be called when there is no active payment going on.

TODO cancelling a payment?

Adds paymentMethodOptions.browserInfo to data before sending it to
app/startPayment.
*/
export function startPayment(data = {}) {
    if (typeof data.paymentMethodOptions !== 'object') {
        data.paymentMethodOptions = {}
    }

    data.paymentMethodOptions.browserInfo = adyenUtils.getBrowserInfo()

    return new Promise((resolve, reject) => {
        paymentOperation.start(resolve, reject)
        window.postRequest('app/startPayment', data)
            .then(handleStartPaymentOrContinuePaymentResult)
            .fail(handleStartPaymentOrContinuePaymentResult)
    })
}

function handleStartPaymentOrContinuePaymentResult(response) {
    endActive3DS2Operation()

    // We're in fail handler
    // TODO split this into a separate function and leave the rest as the success handler
    if (response.status && response.responseText) {
        if (response.status !== 200) {
            log('Error:', response.responseText)
            log('Whole response:', response)
            paymentOperation.finish({ success: false, error: new Error(response.responseText) })
            return
        }
    }

    log('Payment result', response.result, response.result === '3ds2ActionRequired' ? response.data.resultCode : '')

    if (response.result === 'ok') {
        paymentOperation.finish({ success: true, result: response.data })
    } else if (response.result === 'error') {
        paymentOperation.finish({ success: false, error: new Error('TODO result = error') })
    } else if (response.result === 'cancelled') {
        paymentOperation.finish({ success: false, error: new Error('TODO result = cancelled') })
    } else if (response.result === 'refused') {
        paymentOperation.finish({ success: false, error: new Error('TODO result = refused') })
    } else if (response.result === 'redirect') {
        handleRedirect(response)
    } else if (response.result === '3ds2ActionRequired') {
        if (response.data.resultCode === 'IdentifyShopper') {
            handleIdentifyShopper(response)
        } else if (response.data.resultCode === 'ChallengeShopper') {
            handleChallengeShopper(response)
        } else {
            paymentOperation.finish({ success: false, error: new Error('TODO result = refused') })
            throw new Error('unknown resultCode')
        }
    } else {
        log('handleStartPaymentOrContinuePaymentResult(): unknown result code', response)

        paymentOperation.finish({
            success: false,
            error: new Error(`Unsupported result code ${response.result}`),
        })
    }
}

function continuePaymentAfterIdentifyShopper(eventData) {
    if (!window.active3DS2Operation) {
        throw new Error('no active 3DS2 operation')
    }

    const originalResponse = window.active3DS2Operation.originalResponse
    const paymentId = originalResponse.paymentId
    const previousResultCode = originalResponse.data.resultCode
    const originalAdditionalData = originalResponse.data.additionalData

    const threeDSCompInd = eventData.threeDSCompInd
    const threeDS2Token = originalAdditionalData['threeds2.threeDS2Token']

    const data = {
        paymentId,
        data: {
            threeDS2Token,
            threeDSCompInd,
        },
        previousResultCode
    }

    window.postRequest('app/continuePayment', data)
        .then(handleStartPaymentOrContinuePaymentResult)
        .fail(handleStartPaymentOrContinuePaymentResult)
}

function continuePaymentAfterChallengeShopper(eventData) {
    if (!window.active3DS2Operation) {
        throw new Error('no active 3DS2 operation')
    }

    const originalResponse = window.active3DS2Operation.originalResponse
    const previousResultCode = originalResponse.data.resultCode
    const paymentId = originalResponse.paymentId
    const originalAdditionalData = originalResponse.data.additionalData

    const transStatus = eventData.transStatus
    const threeDS2Token = originalAdditionalData['threeds2.threeDS2Token']

    const data = {
        paymentId,
        data: {
            threeDS2Token,
            threeDS2Result: {
                transStatus,
            },
        },
        previousResultCode
    }

    window
        .postRequest('app/continuePayment', data)
        .then(handleStartPaymentOrContinuePaymentResult)
        .fail(handleStartPaymentOrContinuePaymentResult)
}

function continuePaymentAfterRedirectShopper(eventData) {
    if (!window.active3DS2Operation) {
        throw new Error('no active 3DS2 operation')
    }

    const originalResponse = window.active3DS2Operation.originalResponse
    const previousResultCode = originalResponse.data.resultCode
    const paymentId = originalResponse.paymentId

    const md = eventData.MD
    const paResponse = eventData.PaRes

    const data = {
        paymentId,
        data: {
            md,
            paResponse,
        },
        previousResultCode
    }

    log('Calling continuePayment', JSON.parse(JSON.stringify(data)))

    window
        .postRequest('app/continuePayment', data)
        .then(handleStartPaymentOrContinuePaymentResult)
        .fail(handleStartPaymentOrContinuePaymentResult)
}

const URL_BASE = location.origin + '/'
const ADYEN_3DS_NOTIFICATION_URL = URL_BASE + 'adyen/3dsNotification'

// This is mostly copied from function called perform3DSDeviceFingerprint
function removeChildInOneSecond(threedsContainer, form) {
    setTimeout(function() {
        if (form.parentElement === threedsContainer) {
            threedsContainer.removeChild(form)
        }
    }, 1000)
}

function getIframeContainerElement() {
    return document.querySelector('#threeDS2Container')
}

function startActive3DS2Operation(operation) {
    if (window.active3DS2Operation) {
        log('Existing operation:', window.active3DS2Operation)
        throw new Error('3DS operation already active!')
    }

    window.active3DS2Operation = operation
    getIframeContainerElement().classList.remove('hidden')
}

const endActive3DS2Operation = function() {
    window.active3DS2Operation = null
    getIframeContainerElement().classList.add('hidden')
}

// Similar to Adyen utilities' createForm but with multiple inputs
function createFormWithInputs(name, action, target, inputs) {
    if (!name || !action || !target || !inputs) {
        throw new Error('Not all required parameters provided for form creation')
    }

    if (name.length === 0 || action.length === 0 || target.length === 0 || inputs.length === 0) {
        throw new Error('Not all required parameters have suitable values')
    }

    const form = document.createElement('form')
    form.style.display = 'none'
    form.name = name
    form.action = action
    form.method = 'POST'
    form.target = target
    for (const i of inputs) {
        const { name, value } = i
        const input = document.createElement('input')
        input.name = name
        input.value = value
        form.appendChild(input)
    }
    return form
}

function findLargestPossibleWindowSize() {
    // Just using '05' for the window size results in 100% wide iframe with no margin,
    // which is not pretty. Instead, use the width to find the largest one we can use.

    const validChallengeIframeSizes = {
        '01': { width: 250, height: 400 },
        '02': { width: 390, height: 400 },
        '03': { width: 500, height: 600 },
        '04': { width: 600, height: 400 },
        // 100% x 100% really but works for us
        '05': { width: 2000, height: 2000 },
    }

    let result = '01'
    // The number 30 comes from app/css/layout.css, see #threeDS2Container and the iframe
    // contained by it
    const maximumWidth = window.innerWidth - 30

    for (const windowSize in validChallengeIframeSizes) {
        const size = validChallengeIframeSizes[windowSize]
        if (size.width <= maximumWidth) {
            result = windowSize
        }
    }

    return result
}

function handleRedirect(response) {
    // Not really a 3DS2 operation (or could be?) but who cares?
    startActive3DS2Operation({
        type: 'RedirectShopper',
        originalResponse: response,
    })

    const {
        createIframe,
        validateChallengeWindowSize,
        getChallengeWindowSize,
    } = window.threeDS2.adyenUtils

    const responseData = response.data

    const challengeWindowSize = validateChallengeWindowSize(findLargestPossibleWindowSize())

    const PaReq = responseData.paRequest
    const MD = responseData.md
    const TermUrl = ADYEN_3DS_NOTIFICATION_URL
    const issuerUrl = responseData.issuerUrl

    const threeDS2Container = getIframeContainerElement()

    const data = {
        PaReq,
        MD,
        TermUrl,
    }

    const inputs = Object.keys(data).map(key => ({ name: key, value: data[key] }))

    const IFRAME_NAME = 'redirectIframe'

    const iframeSizesArr = getChallengeWindowSize(challengeWindowSize)

    // Create iframe with challenge window dimensions
    const iframe = createIframe(threeDS2Container, IFRAME_NAME, iframeSizesArr[0], iframeSizesArr[1])

    // Create a form that will use the iframe to POST data to the acsURL
    const form = createFormWithInputs('redirectForm', issuerUrl, IFRAME_NAME, inputs)

    threeDS2Container.appendChild(form)
    removeChildInOneSecond(threeDS2Container, form)
    form.submit()
}

// at https://docs.adyen.com/classic-integration/3d-secure/native-3ds2/browser-based-integration/3d-secure-2-helper-functions/
function handleIdentifyShopper(response) {
    startActive3DS2Operation({
        type: 'IdentifyShopper',
        originalResponse: response,
    })

    const { base64Url, createIframe, createForm } = window.threeDS2.adyenUtils

    const responseData = response.data

    const serverTransactionID = responseData.additionalData['threeds2.threeDSServerTransID']
    const threeDSMethodURL = responseData.additionalData['threeds2.threeDSMethodURL']
    const threedsContainer = document.getElementById('threeDS2Container')
    const dataObj = {
        threeDSServerTransID: serverTransactionID,
        threeDSMethodNotificationURL: ADYEN_3DS_NOTIFICATION_URL,
    }
    const stringifiedDataObject = JSON.stringify(dataObj)
    // Encode data
    const base64URLencodedData = base64Url.encode(stringifiedDataObject)
    const IFRAME_NAME = 'threeDSMethodIframe'

    // Create hidden iframe
    const iframe = createIframe(threedsContainer, IFRAME_NAME, '0', '0')

    // Create a form that will use the iframe to POST data to the threeDSMethodURL
    const form = createForm(
        'threedsMethodForm',
        threeDSMethodURL,
        IFRAME_NAME,
        'threeDSMethodData',
        base64URLencodedData
    )

    threedsContainer.appendChild(form)
    removeChildInOneSecond(threedsContainer, form)

    form.submit()
}

// This should be mostly copied from function called perform3DSChallenge
// at https://docs.adyen.com/classic-integration/3d-secure/native-3ds2/browser-based-integration/3d-secure-2-helper-functions/
function handleChallengeShopper(response) {
    startActive3DS2Operation({
        type: 'ChallengeShopper',
        originalResponse: response,
    })

    const {
        base64Url,
        createIframe,
        createForm,
        validateChallengeWindowSize,
        getChallengeWindowSize,
    } = window.threeDS2.adyenUtils

    const responseData = response.data

    const challengeWindowSize = validateChallengeWindowSize(findLargestPossibleWindowSize())

    // Extract the ACS hosted url that will provide the content for the challenge iframe
    const acsURL = responseData.additionalData['threeds2.threeDS2ResponseData.acsURL']

    // Collate the data required to make a cReq
    const cReqData = {
        threeDSServerTransID:
			responseData.additionalData['threeds2.threeDS2ResponseData.threeDSServerTransID'],
        acsTransID: responseData.additionalData['threeds2.threeDS2ResponseData.acsTransID'],
        messageVersion: responseData.additionalData['threeds2.threeDS2ResponseData.messageVersion'],
        messageType: 'CReq',
        challengeWindowSize,
    }

    const stringifiedDataObject = JSON.stringify(cReqData)

    // Encode data
    const base64URLencodedData = base64Url.encode(stringifiedDataObject)

    const IFRAME_NAME = 'threeDSChallengeIframe'

    const threedsContainer = document.getElementById('threeDS2Container')

    const iframeSizesArr = getChallengeWindowSize(challengeWindowSize)

    // Create iframe with challenge window dimensions
    const iframe = createIframe(threedsContainer, IFRAME_NAME, iframeSizesArr[0], iframeSizesArr[1])

    // Create a form that will use the iframe to POST data to the acsURL
    const form = createForm('cReqForm', acsURL, IFRAME_NAME, 'creq', base64URLencodedData)

    threedsContainer.appendChild(form)

    removeChildInOneSecond(threedsContainer, form)

    form.submit()
}

function hideIframe() {
    const threeDS2Container = document.getElementById('threeDS2Container')

    if (threeDS2Container) {
        threeDS2Container.innerHTML = ''
    }
}

// Global event handler for all iframe postMessages that handles all the things
// posted by our 3DS2 handler iframe.
const messageHandler = e => {
    const YOUR_HOSTED_DOMAIN_FOR_THE_NOTIFICATION_URLS = location.origin

    if (e.origin === YOUR_HOSTED_DOMAIN_FOR_THE_NOTIFICATION_URLS) {
        const eventData = e.data

        if (eventData.threeDSCompInd) {
            // Event looks like response from IdentifyShopper.
            if (!window.active3DS2Operation) {
                throw new Error('No payment operation was active after IdentifyShopper postMessage')
            }

            if (window.active3DS2Operation.type !== 'IdentifyShopper') {
                throw new Error(
                    'Active operation was not IdentifyShopper: ' + window.active3DS2Operation.type
                )
            }

            continuePaymentAfterIdentifyShopper(eventData)
            hideIframe()
        } else if (eventData.transStatus && eventData.threeDSServerTransID) {
            // Event looks like response from ChallengeShopper.

            if (!window.active3DS2Operation) {
                throw new Error('No payment operation was active after ChallengeShopper')
            }

            if (window.active3DS2Operation.type !== 'ChallengeShopper') {
                throw new Error(
                    'Active operation was not ChallengeShopper: ' + window.active3DS2Operation.type
                )
            }

            continuePaymentAfterChallengeShopper(eventData)
            hideIframe()
        } else if (eventData.PaRes && eventData.MD) {
            // Event looks like response from RedirectShopper.

            if (!window.active3DS2Operation) {
                throw new Error('No payment operation was active after RedirectShopper')
            }

            if (window.active3DS2Operation.type !== 'RedirectShopper') {
                throw new Error(
                    'Active operation was not RedirectShopper: ' + window.active3DS2Operation.type
                )
            }

            continuePaymentAfterRedirectShopper(eventData)
            hideIframe()
        } else {
            // log('Unknown event', eventData)
        }
    }
}

export function init3DS2MessageHandler() {
    window.addEventListener('message', messageHandler)
}
