import { providerEmitter } from './providerEmitter'
import { ProviderMarker } from './marker/providerMarker'
import { eventManager } from './eventManager'

export var providerManager = {}

// all providers in the service
providerManager.compactProviders = {}

// nearby providers. only offers from these
providerManager.providerMarkers = {}

// nearby detailed potential providers. structure: { <providerId>: { listener: {}, value: {} } }
// all these are in providerMarkers
providerManager.detailedProviders = {}

// nearby offers in order. offers from detailedProviders.
providerManager.offers = []

providerManager.start = function() {
    window.logLoad('start provider manager')
    providerEmitter.listenAllCompactProviders(compactProviderCallbacks)
}
providerManager.getProvider = function(providerId) {
    return providerEmitter.getProvider(providerId).then(provider => {
        processDetailedProvider(provider)
        return provider
    })
}

providerManager.rebuildAllMarkers = () => {
    for (var pId in providerManager.providerMarkers) {
        providerManager.providerMarkers[pId].remove()
    }
    providerManager.providerMarkers = {}
    window.logLoad('rebuild all markers')
    window.updateNearbyProviders()

}

var compactProviderCallbacks = {
    add: provider => {
        provider.badgeNumber = provider.unitsLeft
        // add
        providerManager.compactProviders[provider.id] = provider

        window.logLoad('compact add')

        window.updateNearbyProviders()
    },
    change: provider => {
        provider.badgeNumber = provider.unitsLeft
        // changed
        providerManager.compactProviders[provider.id] = provider
        var marker = providerManager.providerMarkers[provider.id]
        if (marker)
            marker.setProvider(provider)

        window.logLoad('compact change')

        window.updateNearbyProviders()
    },
    remove: providerId => {
        // remove

        delete providerManager.compactProviders[providerId]

        window.logLoad('compact remove')

        window.updateNearbyProviders()
    }
}

var detailedProviderCallbacks = {
    change(detailedProvider) {
        providerManager.detailedProviders[detailedProvider.id].value = detailedProvider
        updateOffers()
    },
    remove(providerId) {
        // listener already stopped
        delete providerManager.detailedProviders[providerId]
        updateOffers()
    }
}

function addMarker(provider) {
    var marker = new ProviderMarker(provider)
    marker.setMap(window.activeMap)
    providerManager.providerMarkers[provider.id] = marker
}
function removeMarker(providerId) {
    var marker = providerManager.providerMarkers[providerId]
    if (marker) marker.remove()
    delete providerManager.providerMarkers[providerId]
}

function addDetailedProvider(providerId) {
    var listener = providerEmitter.listenProvider(providerId, detailedProviderCallbacks)
    providerManager.detailedProviders[providerId] = {
        listener,
        value: null
    }
}
function processDetailedProvider(detailedProviderValue) {
    var processed = detailedProviderValue.distance !== undefined

    if (window._center) {
        // calculate new distance anyway

        var lat = window._center.lat()
        var lng = window._center.lng()
        detailedProviderValue.distance = window.calcCrow(lat, lng, detailedProviderValue.latitude, detailedProviderValue.longitude)
    }
    detailedProviderValue.distanceWeight = detailedProviderValue.distance
    if (window.myProfile && window.myProfile.favouriteProviders && window.myProfile.favouriteProviders[detailedProviderValue.id])
        detailedProviderValue.distanceWeight /= 2

    if (!processed) {
        if (!detailedProviderValue.offers)
            detailedProviderValue.offers = []

        // Web client does not support sold out offers yet.
        detailedProviderValue.offers = detailedProviderValue.offers.filter(o => o.unitsLeft > 0)

        detailedProviderValue.offers.forEach(o => {
            o.provider = detailedProviderValue
            if (o.tags)
                o.tags = o.tags.map(t => use.env.TAGS[t]).filter(Boolean)
            else
                o.tags = []
        })
        detailedProviderValue.country = use.env.COUNTRIES[detailedProviderValue.country]
    }
}
function removeDetailedProvider(providerId) {
    providerManager.detailedProviders[providerId].listener.stop()
    delete providerManager.detailedProviders[providerId]
}

var updateNearbyProvidersTimeout = null
window.updateNearbyProviders = window.debounce(function() {
    if (!window._radius || !window._center) {
        if (window.updateRadius)
            window.updateRadius()
        setTimeout(window.updateNearbyProviders, 100)
        return
    }

    var lat = window._center.lat()
    var lng = window._center.lng()
    var maxRadius = Math.max(window._radius * 1.3, 0.2) + 0.1

    function setDistances(p) { p.distance = window.calcCrow(lat, lng, p.latitude, p.longitude) }
    function closeEnough(p) { return p.distance < maxRadius }
    function sortNearbyProviders(a, b) {
        var badgeDiff = !!a.badgeNumber - !!b.badgeNumber
        if (badgeDiff !== 0) { return -badgeDiff } // most offers first
        return a.distance - b.distance
    }
    function pilotCountryFilter(p) {
        if (p.requiredCode) {
            if (window.myProfile) {
                for (let i = 0; i < window.myProfile.codes.length; ++i) {
                    if (window.myProfile.codes[i].code === p.requiredCode)
                        return true
                }
                return false
            } else {
                return false
            }
        }
        return true
    }

    // Get nearby data
    var nearbyProviders = Object.values(providerManager.compactProviders)
    window.logLoad('nearby providers with ' + nearbyProviders.length)
    nearbyProviders.forEach(setDistances)
    nearbyProviders = nearbyProviders
        .filter(closeEnough)
        .filter(pilotCountryFilter)
        .sort(sortNearbyProviders).slice(0, 100)

    updateMarkers(nearbyProviders)

    // Get detailed data
    var potentialNearbyProviderIds = nearbyProviders.filter(p => p.badgeNumber > 0).map(p => p.id)
    var potentialNearbyProviderIdSet = window.fromArrayToObjectSet(potentialNearbyProviderIds)
    var newDetailedProviderIds = potentialNearbyProviderIds.filter(pId => !providerManager.detailedProviders[pId])
    // var newDetailedProviderIdSet = new Set(newDetailedProviderIds);
    var detailedProviderIdsToDelete = Object.keys(providerManager.detailedProviders).map(pIdString => pIdString | 0)
        .filter(pId => !potentialNearbyProviderIdSet[pId])

    // Delete detailed providers
    detailedProviderIdsToDelete.forEach(removeDetailedProvider)

    // Add detailed providers
    newDetailedProviderIds.forEach(addDetailedProvider)

    updateOffers()
}, 50)

function updateMarkers(nearbyProviders) {
    wait.ProviderMarkerIsReady.then(() => {
        // new Set([]) not supported in IE
        var nearbyProviderIdSet = window.fromArrayToObjectSet(nearbyProviders.map(p => p.id))

        // Get marker data
        var newMarkerProviderIds = nearbyProviders.filter(p => !providerManager.providerMarkers[p.id]).map(p => p.id)

        var markerProviderIdsToDelete = Object.keys(providerManager.providerMarkers).map(pIdString => pIdString | 0).filter(pId => !nearbyProviderIdSet[pId])

        // Delete markers
        markerProviderIdsToDelete.forEach(removeMarker)

        // Add markers
        newMarkerProviderIds.map(pId => providerManager.compactProviders[pId]).forEach(addMarker)

        for (var pId in providerManager.providerMarkers) {
            providerManager.providerMarkers[pId].updateElement()
        }
    })
}

var updateOffers = window.debounce(function() {
    var providers = Object.values(providerManager.detailedProviders).map(dP => dP.value).filter(Boolean)
    providers.forEach(processDetailedProvider)

    providerManager.offers = [].concat(...providers.map(p => p.offers))

    eventManager.dispatch('offersUpdated', providerManager.offers)
}, 50)
