// Try to remory query part from location using HTML5 history API
export function removeQueryParameters() {
    wait.env.then(env => {
        if (env.DEV_ENV) {
            if (window.history && window.history.pushState) {
                history.pushState('', document.title, window.location.pathname)
            }
        } else {
            if (window.history && window.history.replaceState) {
                history.replaceState('', document.title, window.location.pathname)
            }
        }
    })
}
