// Tool to analyze loading times
export function logLoad(name) {
    function logLine(name, value) {
        console.log(logLoad.formatDate(value) + ' (' + (value - logLoad.startLoading) + ' ms) > '  + name)
    }
    if (name) {
        if (!logLoad.data[name])
            logLoad.data[name] = Date.now()
    } else {
        logLine('start loading', logLoad.startLoading)
        Object.keys(logLoad.data).sort(function(a, b) { return logLoad.data[a] - logLoad.data[b] }).forEach(function(key) {
            logLine(key, logLoad.data[key])
        })
    }
}
logLoad.formatDate = function(date) { date = new Date(date); return date.toTimeString().substring(0, 8) + ':' + date.getMilliseconds() }
logLoad.startLoading = Date.now()
logLoad.data = {}

window.logLoad = logLoad
