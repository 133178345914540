import { el, list, mount } from 'redom'

export function askToAcceptTermsOfUse(callback, creatingNewUser, dismissCallback) {
	
    /*
	// TODO
	if (!use.env.DEV_ENV) {
		callback && callback();
		return; // Not yet in production. Please localize this popup.
	}
	*/

    const skipEndpoint = creatingNewUser
	
    const termsUpdated = true
	
    let title = lang.terms.title
    let text = lang.terms.text
    let termsOfUse = lang.terms.termsOfUse
    let privacyPolicy = lang.terms.privacyPolicy
    let agree = lang.terms.agree
    let cancel = lang.terms.cancel
	
    if (!creatingNewUser) {
        title = lang.termsUpdated.title
        text = lang.termsUpdated.text
        termsOfUse = lang.termsUpdated.termsOfUse
        privacyPolicy = lang.termsUpdated.privacyPolicy
        agree = lang.termsUpdated.agree
        cancel = lang.termsUpdated.cancel
    }
	
    text = text.replace('#(termsOfUse)', `<a href="/terms" target="_blank">${termsOfUse}</a>`)
    text = text.replace('#(privacyPolicy)', `<a href="/privacy" target="_blank">${privacyPolicy}</a>`)
	
    let agreeClicked = false
	
    var dlg = new window.ModalDialog(title, {
        width: '500px',
        hideCallback: () => {
            if (!agreeClicked && dismissCallback)
                dismissCallback()
        }
    })
    dlg.add(text)
    dlg.addButton(agree, function() {
        agreeClicked = true
        dlg.hide() // will call the goToMyLocation (cancel)
        if (skipEndpoint) {
            callback && callback()
        } else {
            acceptTermsOfUse().done(callback).fail(window.dialogFailResponse)
        }
    })
    dlg.show()
}

function acceptTermsOfUse(optionalVersion) {
    return window.postRequest('app/acceptTermsOfUse/' + (optionalVersion === undefined ? use.env.TERMS_OF_USE_VERSION : optionalVersion))
}
