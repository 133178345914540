window.fbAsyncInit = function() {
    window.FB.init({
        appId: '1781861932075657',
        xfbml: false,
        version: 'v8.0'
    })

    wait.env.then(function(env) {
        waitNoMore.FB(window.FB)

        if (env.DEV_ENV) {
            window.disableVariable.FBAppEvents()
        } else {
            waitNoMore.FBAppEvents(window.FB.AppEvents)
            window.FB.AppEvents.logPageView()
        }
    })
}

window.addEventListener('load', () => {
    (function(d, s, id) {
      wait.lang.then(function () {
        var js, fjs = d.getElementsByTagName(s)[0]
        if (d.getElementById(id)) {return}
        js = d.createElement(s); js.id = id
        js.src = `//connect.facebook.net/${window.lang.meta.language}/sdk.js`
        fjs.parentNode.insertBefore(js, fjs)
      })
    }(document, 'script', 'facebook-jssdk'))
})
