// Parse query parameters as they are in location.search.
// queryparser('?foo=123&author+name=Kernighan%2B%26%2BRitchie') => {
//   foo: '123',
//   'author name': 'Kernighan & Ritchie'
// }
export function queryParser(search) {
    if (search[0] === '?') {
        search = search.slice(1)
    }
    const decode = part => decodeURIComponent(part.replace(/\+/g, '%20'))
    var result = {}
    search.split('&').forEach(part => {
        const [key, value] = part.split('=').map(decode)
        result[key] = value
    })
    return result
}
