/*
 class MyMarker extends AbstractMarker {
 }

 var marker = new MyMarker();
 marker.setMap(map);
 ...
 marker.remove();

 */
export class AbstractMarker {
    constructor() {
        this.element = null
    }
    remove() {
        this.setMap(null) // calls onRemove
        this.freeResources()
    }
    onRemove() {
        this.element.parentNode.removeChild(this.element)
        this.element = null
    }
    onAdd() {
        this.element = this.createElement()
        this.element.style.position = 'absolute'
        this.element.style.x = 0
        this.element.style.y = 0


        this.getPanes().overlayImage.appendChild(this.element)
    }
    draw() {
        this.update(this.element, this.getProjection())
    }

    // This can be called in update function
    setLatLng(latLng, overlayProjection) {
        var { x, y } = overlayProjection.fromLatLngToDivPixel(latLng)
        x = Math.round(x)
        y = Math.round(y)
        this.element.style.zIndex = 1000 + y
        this.element.style.transform = `translate(${ x }px, ${ y }px)`
    }

    // override these:

    createElement() {
        // return dom element
    }
    update(element, overlayProjection) {
        // move your element to correct position
    }
    freeResources() {
    }
}
// When google maps received, AbstractMarker will extend google.maps.OverlayView
wait.googleMaps.then(() => {
    window.extendClassAfterwards(AbstractMarker, window.google.maps.OverlayView)
    waitNoMore.AbstractMarkerIsReady()
})
