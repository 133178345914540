import { adyenUtils } from './threeDS2'

class PaymentClientError extends Error {
    constructor(errorCode, errorParams) {
        super(errorCode)
        this.errorParams = errorParams
    }
}

export function startPayment(data) {
    if (typeof data.paymentMethodOptions !== 'object') {
        data.paymentMethodOptions = {}
    }

    data.paymentMethodOptions.browserInfo = adyenUtils.getBrowserInfo()

    window
        .postRequest('app/startPayment', data)
        .then(handlePaymentResponse)
        .fail(handlePaymentFailure)
}

export function continuePayment({ redirectResult, paymentId }) {
    window
        .postRequest('app/continuePayment', {
            paymentId: paymentId,
            // This is added as early change for AdyenCheckout API
            // and subsequent PR that will move previousResultCode into the database
            previousResultCode: 'PLACEHOLDER_PLEASE_IGNORE',
            data: { redirectResult },
        })
        .then(handlePaymentResponse)
        .fail(handlePaymentFailure)
}

function handlePaymentResponse(response) {
    const { result, data } = response
    switch (result) {
    case 'ok':
        window.updateMyProfile()
        window.showAlert(response.data.message).then(() => window.rootView.show())
        return
    case 'redirect':
        window.open(data.action.url, '_self')
        return
    default:
        throw new PaymentClientError('UnknownResult', result)
    }
}

function handlePaymentFailure(responseOrError) {
    if (responseOrError instanceof Error) {
        throw responseOrError
    }

    const { result } = responseOrError
    switch (result) {
    case 'error':
    case 'cancelled':
    case 'refused':
        throw new PaymentClientError('NotImplemented')
    default:
        throw new PaymentClientError('UnknownResult', result)
    }
}
