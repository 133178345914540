// searchParameters:
// {
//   matchString: 'Hanko'
// }
//

function caseInsensitiveMatch(string, matchString) {
    return string.toLowerCase().match(matchString.toLowerCase())
}

export function doesOfferMatchSearchParameters(offer, searchParameters) {
    if (searchParameters.matchString) {
        const str = searchParameters.matchString
        const matchName = caseInsensitiveMatch(offer.name, str)
        const matchDescription = caseInsensitiveMatch(offer.description, str)
        const restaurantNameMatch = caseInsensitiveMatch(offer.provider.name, str)

        if (matchName || matchDescription || restaurantNameMatch) {
            // Continue matching
        } else {
            return false
        }
    }

    return true
}
