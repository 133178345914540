import { removeQueryParameters } from './util/removeQueryParameters'

// TODO put this inside a function and call it explicitly
wait.query.then(query => {

    if (query.redirectResult) {
        window.paymentClient.continuePayment({
            redirectResult: query.redirectResult,
            paymentId: query.paymentId,
        })
    }

    if (query.referralCode) {
        wait.env.then(env => {
            if (env.user) {
                new window.SettingsCodeView(null, { predefinedCode: query.referralCode }).show()
            } else {
                window.addCodeAfterLogin(query.referralCode)
            }
        })
    }

    removeQueryParameters()
})
