import { el, list, mount } from 'redom'
import { OfferList } from './ui/offerList'
import './registerPromisefulVariables'

import './logLoad'
import './disableGoogleMapsFontLoading'

import './analytics'

import './handleQueryParameters'

import { queryParser } from './queryParser'

import { providerManager } from './providerManager'
import { searchParametersManager } from './searchParametersManager'
import { eventManager } from './eventManager'
import { askToAcceptTermsOfUse } from './ui/termsOfUse'
// Needs to be imported to install event handler
import { adyenUtils, init3DS2MessageHandler, startPayment } from './threeDS2'
import * as paymentClient from './paymentClient'
import '../../common/js/external/ResQ_Brother.js'


window.OfferList = OfferList

window.providerManager = providerManager

window.searchParametersManager = searchParametersManager

window.eventManager = eventManager

window.askToAcceptTermsOfUse = askToAcceptTermsOfUse

window.paymentClient = {
    startPayment: paymentClient.startPayment,
    continuePayment: paymentClient.continuePayment,
}

window.threeDS2 = {
    adyenUtils,
    startPayment
}

window.redom = {
    el,
    list,
    mount
}


wait.env.then(() => providerManager.start())
waitNoMore.query(queryParser(location.search))

// This is is wrong place
class PriceTag {
    constructor() {
        this.el = el('div.priceTag.priceTag-normal',
            this.text = el('div.priceTextDiv')
        )
    }
    update(price, currency) {
        var decimals = currency.decimals
        var number = price / Math.pow(10, decimals)
        var numberFormatted = window.formatNumber(number, decimals)
        this.text.innerHTML = currency.format.replace('%@', `<span class="price">${ numberFormatted }</span>`)
    }
}
window.PriceTag = PriceTag

init3DS2MessageHandler()
