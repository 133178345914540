import { el, list, mount } from 'redom';

import { doesOfferMatchSearchParameters } from './search';

export class OfferList {
  constructor() {
    const notSuitableText = `${lang.feed.notSuitable.header}:`;

    this.el = el(
      'div.offerRowContainer',
      (this.nonFiltered = list('div.offerRows', OfferRow, 'id')),
      (this.filteredInfo = el('div.offerFilterSeparator', notSuitableText, {
        style: 'display: none;',
      })),
      (this.filtered = list('div.offerRows', OfferRow, 'id'))
    );
  }

  update(offers, searchParameters = {}) {
    offers = offers.filter((off) => {
      return doesOfferMatchSearchParameters(off, searchParameters);
    });

    if (offers.length === 0) {
      this.nonFiltered.update([]);
      this.filteredInfo.style.display = 'none';
      this.filtered.update([]);
      return;
    }
    let requiredTags = [];

    if (window.myNotificationSettings) {
      const notificationTags = JSON.parse(JSON.stringify(window.myNotificationSettings.tags));

      requiredTags = notificationTags
        .map((tId) => use.env.TAGS[tId])
        .map((tag) => window.fromArrayToObjectSet([tag.id, ...tag.accept]));
    }

    for (var pId in offers) {
      var o = offers[pId];
      delete o.isDessert;
      o.tags.forEach((t) => {
        if (!t) return console.log('Tag is not defined', o.tags, pId, offers, offers[pId]);

        if (t.id === 'dessert') o.isDessert = true;
        if (t.type === 'foodType') o.foodType = t.id;
      });

      var filtersOk = true;

      // every required tag must be found
      for (let i = 0; i < requiredTags.length; i++) {
        const requiredTagObjectSet = requiredTags[i];

        var found = false;
        for (var j = 0; j < o.tags.length; j++) {
          if (requiredTagObjectSet[o.tags[j].id]) {
            found = true;
            break;
          }
        }
        if (!found) {
          filtersOk = false;
          break;
        }
      }

      o.filteredOut = !filtersOk;
    }

    offers.sort((a, b) => {
      if (a.filteredOut !== undefined) {
        var filteredOutDiff = a.filteredOut - b.filteredOut;
        if (filteredOutDiff != 0) return filteredOutDiff;
      }

      var dessertDiff = ~~a.isDessert - ~~b.isDessert;
      if (dessertDiff != 0) return dessertDiff;

      // Provider distance (favourites compensates)
      var distanceDiff = a.provider.distanceWeight - b.provider.distanceWeight;
      if (distanceDiff != 0) return distanceDiff;

      // Offer name
      if (a.name < b.name) return -1;
      else if (a.name > b.name) return 1;

      return 0;
    });

    offers = offers.slice(0, 100); // Limit to 100

    var nonFiltered = offers;
    var filtered = [];
    for (let i = 0; i < offers.length; i++) {
      if (offers[i].filteredOut) {
        nonFiltered = offers.slice(0, i);
        filtered = offers.slice(i);
        break;
      }
    }
    this.nonFiltered.update(nonFiltered);
    if (filtered.length > 0) this.filteredInfo.style.display = 'block';
    else this.filteredInfo.style.display = 'none';
    this.filtered.update(filtered);

    if (offers.length > 0) window.logLoad('offer list created');
  }
}

class OfferRow {
  constructor() {
    this.offer = null;
    const self = this;
    this.el = el(
      'div.offerRow',
      (this.content = el(
        'div.offerRow-content',
        (this.price = new window.PriceTag()),
        (this.name = el('div.offerRowName')),
        (this.details = el(
          'div.offerRow-details',
          el('span.icon-hat.green'),
          (this.providerName = el('div.offerRowProviderName')),
          el('div.spacer'),
          el('span.icon-clock.grayEffect'),
          (this.fetchDL = el('div.offerRowFetchDL.grayEffect')),
          el('span.icon-portions.grayEffect'),
          (this.portions = el('div.offerRowQuantity.grayEffect'))
        ))
      )),
      {
        onmouseenter: () => {
          window.buildOfferDetails($('#offerHoverBox').stop().fadeIn('fast'), this.offer);
          window.startProviderLocationEffect(this.offer.provider);
        },
        onmouseleave: () => {
          $('#offerHoverBox').stop().fadeOut('fast');
          window.endProviderLocationEffect();
        },
        onclick: () => {
          $('#offerHoverBox').stop().fadeOut('fast');
          setTimeout(() => {
            new window.OfferDetailsView(null, this.offer).show();
          }, 0);
        },
      }
    );
  }

  update(offer) {
    this.offer = offer;
    this.name.textContent = offer.name;
    this.providerName.textContent = offer.provider.name;
    this.fetchDL.textContent = window.formatTime(offer.fetchEnd);
    this.portions.textContent = offer.unitsLeft;

    const isNonsurplus = !!offer.tags.some((t) => t.id === 'nonsurplus');
    // IE doesn't support classList.toggle 2nd param
    if (isNonsurplus) {
      this.price.el.classList.add('nonsurplus-background');
    } else {
      this.price.el.classList.remove('nonsurplus-background');
    }

    const price = offer.dynamicPrice;

    // TODO find out why this happens on IE 11 and fix it.
    // console.log('offer', offer);
    // console.log('provider', offer.provider);
    // console.log('country', offer.provider.country);
    if (!offer.provider.country) {
      console.log('!!! You are using IE 11? TODO fix this.');
      return;
    }

    // // On IE, this becomes undefined.
    // console.log('currency', offer.provider.country.currency);

    this.price.update(price, offer.provider.country.currency);
    $(this.content).toggleClass('filteredOut', offer.filteredOut);
    if (offer.foodType) this.el.setAttribute('foodType', offer.foodType);
    this.el.setAttribute('oid', offer.id);
    this.el.setAttribute('pid', offer.provider.id);
  }
}
