export var providerEmitter = {}

providerEmitter.getProviderLocations = function() {
    return wait.env.then(env => {
        if (!firebaseDatabase) return console.warn('providerEmitter.getProviderLocations without firebaseDatabase')
        return firebaseDatabase.ref(use.env.FIREBASE.locationsPath).once('value').then(snapshot => snapshot.val())
    })
}
providerEmitter.getArrangedProviders = function(latitudeLongitude) {
    const key = (latitudeLongitude.latitude | 0) + ',' + (latitudeLongitude.longitude | 0)
    return wait.env.then(env => {
        if (!firebaseDatabase) return console.warn('providerEmitter.getArrangedProviders without firebaseDatabase')
        return firebaseDatabase.ref(use.env.FIREBASE.arrangedPath + key).once('value').then(snapshot => snapshot.val())
    })
}

providerEmitter.getAllCompactProviders = function() {
    return wait.env.then(env => {
        if (!firebaseDatabase) return console.warn('providerEmitter.getAllCompactProviders without firebaseDatabase')
        return firebaseDatabase.ref(use.env.FIREBASE.compactPath).once('value').then(snapshot => snapshot.val())
    })
}

providerEmitter.getProvider = function(providerId) {
    return wait.env.then(_ => {
        if (!firebaseDatabase) return console.warn('providerEmitter.getProvider without firebaseDatabase')
        return firebaseDatabase.ref(use.env.FIREBASE.detailedPath + providerId).once('value').then(snapshot => snapshot.val())
    })
}

/*
 callbacks: {
     add: (compactProvider) => {} // initially this will be called for all providers
     change: (compactProvider) => {}
     remove: (providerId) => {}
 }
 */
providerEmitter.listenAllCompactProviders = function(callbacks) {
    if (!callbacks) throw new Error('needs callbacks')
    if (!firebaseDatabase) return console.warn('providerEmitter.listenAllCompactProviders without firebaseDatabase')

    var listener = new ProviderListener(use.env.FIREBASE.compactPath)

    if (callbacks.add) {
        window.logLoad('listen firebase compact add')
        listener.add('child_added', (snapshot, prevChildKey) => {
            window.logLoad('got firebase compact add')
            callbacks.add(snapshot.val())
        })
    }
    if (callbacks.change) {
        listener.add('child_changed', (childSnapshot, prevChildKey) => {
            callbacks.change(childSnapshot.val())
        })
    }
    if (callbacks.remove) {
        listener.add('child_removed', oldChildSnapshot => {
            callbacks.remove(oldChildSnapshot.val().id)
        })
    }

    return listener
}

/*
 callbacks: {
     change: (detailedProvider) => {} // this will be called also initially
     remove: (providerId) => {}
 }
 */
providerEmitter.listenProvider = function(providerId, callbacks) {
    if (!callbacks) throw new Error('needs callbacks')
    if (!firebaseDatabase) return console.warn('providerEmitter.listenProvider without firebaseDatabase')

    var listener = new ProviderListener(use.env.FIREBASE.detailedPath + providerId)

    if (callbacks.change || callbacks.remove) {
        listener.add('value', (snapshot) => {
            var val = snapshot.val()
            if (val === null) {
                if (callbacks.remove)
                    callbacks.remove(providerId)

                listener.stop()
            } else {
                if (callbacks.change)
                    callbacks.change(val)
            }
        })
    }

    return listener
}

class ProviderListener {
    constructor(path) {
        this.databaseRef = firebaseDatabase.ref(path)
        this.databaseListeners = []
    }
    add(event, callback) {
        this.databaseListeners.push({ event, callback })
        this.databaseRef.on(event, callback)
    }

    stop() {
        this.databaseListeners.forEach(l => this.databaseRef.off(l.event, l.callback))
        this.databaseListeners.length = 0
    }
}

var firebaseDatabase = null

wait.env.then(function () {
    if (window.firebase) {
        window.logLoad('init firebase')
        window.firebase.initializeApp({ databaseURL: use.env.FIREBASE.databaseUrl })
        var db = window.firebase.database()
        if (use.env.ENVIRONMENT_NAME === 'local') {
          console.warn('Using firebase emulator')
          db.useEmulator("localhost", 9000);
        }
        firebaseDatabase = db
        window.logLoad('end of init firebase')
    }
})
