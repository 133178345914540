import { el, mount } from 'redom'
import { AbstractMarker } from './abstractMarker'

export class ProviderMarker {
    constructor(provider) {
        this.setProvider(provider)
    }
    setProvider(provider) {
        this.provider = provider
        this.latLng = new window.google.maps.LatLng({
            lat: provider.latitude,
            lng: provider.longitude
        })
        if (this.element)
            this.updateElement(this.element)
    }
    updateElement() {
        if (!this.element) return

        this.name.textContent = this.provider.name
        if (this.provider.badgeNumber > 0)
            this.quantity.textContent = this.provider.badgeNumber
        $(this.mapMarker).toggleClass('noOffers', this.provider.badgeNumber == 0)
        $(this.quantity).toggleClass('hidden', this.provider.badgeNumber == 0)
        if (window.myProfile && window.myProfile.favouriteProviders && window.myProfile.favouriteProviders[this.provider.id]) {
            this.icon.setAttribute('src', 'img/pin_fav_yellow.png')
        } else {
            this.icon.setAttribute('src', 'img/pin.png')
        }
        window.updateMarkerTextVisibility(this.element)
    }
    createElement(provider) {
        this.element = el('div.mapMarkerContainer',
            this.mapMarker = el('div.mapMarker',
                this.icon = el('img.mapMarkerIcon'),
                this.name = el('div.mapMarkerName'),
                this.quantity = el('div.mapMarkerQuantity')
            )
        )

        $(this.mapMarker).children().hover(() => {
            this.mapMarker.classList.add('mapMarkerHover')
        }, () => {
            this.mapMarker.classList.remove('mapMarkerHover')
        }).click(event => {
            new window.ProviderView(null, this.provider).show()
        })

        this.updateElement()
        return this.element
    }
    update(element, overlayProjection) {
        this.setLatLng(this.latLng, overlayProjection)
    }
    freeResources() {
        this.provider = null
    }
}

wait.AbstractMarkerIsReady.then(() => {
    window.extendClassAfterwards(ProviderMarker, AbstractMarker)
    waitNoMore.ProviderMarkerIsReady()
})
